<template>
	<div class="Login" :style="{height:clientHeight + 'px',width:clientWidth + 'px'}">
		<div class="login_img">
			<img style="width: 300px;" src="../assets/login/logo.png" />
			<img src="../assets/login/login2.png" />
			<div class="login_from">
				<div style="width: 100%;text-align: center;color: green;margin-top: 100px;">
					系统正在升级，新用户请用“扫码登录”
				</div>
				<el-tabs class="item4" v-model=" activeName" :stretch="true" @tab-click="handleClick">
					<el-tab-pane label="密码登录" name="first" style="height: 400px;">
						<el-input class="item2" style="margin-top: 40px;" v-model="login_data.mobile"
							placeholder="请输入账号" @keyup.enter.native="login()">
							<i slot="prefix" class="el-input__icon el-icon-user"></i>
						</el-input>
						<el-input class="item2" style="margin-top: 40px;" v-model="login_data.password"
							placeholder="请输入密码" show-password @keyup.enter.native="login()">
							<i slot="prefix" class="el-input__icon el-icon-lock"></i>
						</el-input>
						<div class="item3" style="margin-top: 20px;">
							<el-checkbox v-model="checked">记住登录密码</el-checkbox>
						</div>
						<el-button class="item4" style="margin-top: 20px;" type="primary" @click="login">登录
						</el-button>
					</el-tab-pane>
					<el-tab-pane label="扫码登录" name="second" style="height: 400px;">
						<div style="display: flex;align-items: center;justify-content: center;">
							<img src="../assets/login/scan.png" width="20px" />
							<div style="margin-left: 10px;"> 微信小程序扫码登录</div>
						</div>
						<img :src="img_url" style="margin: 10px;height: 250px;" />
						
						<el-button  style="margin-top: 20px;width: 200px;font-size: 15px;" type="success" plain round @click="xcxShow=true">快速打开微信小程序
						</el-button>
					</el-tab-pane>
				</el-tabs>
				<!-- <div class="bottom_text">《用户协议》《隐私政策》手机app下载 </div> -->
			</div>
		</div>
		<el-dialog title="用户注册" :visible.sync="registerShow" width="25%" :close-on-click-modal="false"
			:show-close="false">
			<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="姓名" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="mobile">
					<el-input v-model="ruleForm.mobile"></el-input>
				</el-form-item>
				<el-form-item label="单位名称" prop="unit_name">
					<el-input v-model="ruleForm.unit_name"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input v-model="ruleForm.password"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="password_confirmation">
					<el-input v-model="ruleForm.password_confirmation"></el-input>
				</el-form-item>
				<div style="color: #FF0000;">注意：注册信息提交后请及时联系安小智审核开通账户。</div>
				<div class="popover_flex">
					<img style="width: 100px;" src="../assets/home/kefu.png" />
					<div style="margin-left: 10px;">
						<span style="font-weight: bold; font-size: 20px;">微信联系安小智</span>
						<div>anquan0311</div>
					</div>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="registerCancel">取 消</el-button>
				<!-- <el-button type="primary" @click="registerOk">确 定</el-button> -->
			</span>
		</el-dialog>
		<el-dialog title="温馨提示" :visible.sync="payShow" width="25%" :close-on-click-modal="false" :show-close="true">
			<div style="color: #FF0000;font-size: 18px;">您的账号非VIP<br>
				可浏览资料库，不可下载
			</div>
			<div class="popover_flex" style="margin-top: 30px;">
				<img style="width: 100px;" src="../assets/home/kefu.png" />
				<div style="margin-left: 10px;">
					<span style="font-weight: bold; font-size: 20px;">微信联系安小智</span>
					<div>anquan0311</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog  :visible.sync="xcxShow" width="25%" :close-on-click-modal="true" >
			<div style="color: #FF0000;font-size: 18px;">请使用 微信 扫描此二维码<br><br>
			可以快速打开 "应急安全智库" 小程序
			</div>
			<div class="popover_flex" style="margin-top: 30px;">
				<img style="width: 300px;" src="../assets/login/yjzkxcx.jpg" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		request
	} from "../network/request.js";
	export default {
		name: 'Home',
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === undefined) {
					callback(new Error('请输入密码'));
				} else {
					if (this.ruleForm.password_confirmation !== '') {
						this.$refs.ruleForm.validateField('password_confirmation');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === undefined) {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};

			return {
				xcxShow:false,
				img_url: '',
				timer: "",
				saoma: false,
				psw: true,
				payShow: false,
				clientHeight: '',
				clientWidth: '',
				registerShow: false,
				activeName: 'first',
				login_data: {
					mobile: "", //账号
					password: "" //密码
				},
				ruleForm: {
					name: '',
					mobile: '',
					unit_name: '',
					password: '',
					password_confirmation: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					mobile: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							pattern: /^1[34578]\d{9}$/,
							message: '目前只支持中国大陆的手机号码'
						}
					],
					unit_name: [{
						required: true,
						message: '请输入单位名称',
						trigger: 'blur'
					}, ],
					password: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					password_confirmation: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
				},
				checked: false ///是否记住密码
			}
		},
		created() {
			if (window.localStorage.getItem("userMessage") != null) {
				this.checked = true;
				this.login_data = JSON.parse(window.localStorage.getItem("userMessage"));
			}
			this.clientHeight = document.documentElement.clientHeight
			this.clientWidth = document.documentElement.clientWidth
		},
		beforeDestroy() {
			//console.log(this.timer, "清除定时器");
			clearInterval(this.timer);
		},
		methods: {
			registerCancel() {
				this.$refs.ruleForm.resetFields();
				this.activeName = 'first'
				this.registerShow = false
			},
			// registerOk() {
			// 	var obj = this.ruleForm
			// 	obj.stop_at = '0000-00-00'
			// 	this.$refs.ruleForm.validate((valid) => {
			// 		if (valid) {
			// 			request({
			// 				url: 'datum/register',
			// 				method: 'post',
			// 				data: obj
			// 			}).then(res => {
			// 				// console.log(res)
			// 			})
			// 			this.activeName = 'first'
			// 			this.registerShow = false
			// 		} else {
			// 			return false;
			// 		}
			// 	});

			// },
			handleClick(e) {
				// if (e.index == '1') {
				// 	this.registerShow = true
				// 	this.ruleForm = {}
				// }
				clearInterval(this.timer);
				if (e.index == '1') {
					// console.log(55)
					this.saoma = true;
					this.psw = false;
					this.codeLogin();
				} else {
					this.psw = true;
					this.saoma = false;

				}
			},
			login() {
				if (this.checked) {
					window.localStorage.setItem(
						"userMessage",
						JSON.stringify(this.login_data)
					);
				} else {
					window.localStorage.removeItem("userMessage");
					// console.log(window.localStorage.getItem("userMessage"));
				}
				request({
					url: 'datum/login',
					method: 'post',
					data: this.login_data
				}).then(res => {
					if (res.code == 1) {
						let temp_time = new Date()
						// console.log(333333333, temp_time)
						let temp_y = temp_time.getFullYear();
						let temp_m = (temp_time.getMonth() + 1).toString().padStart(2, '0')
						let temp_d = temp_time.getDate().toString().padStart(2, '0')
						let temp_stopat = temp_y + "-" + temp_m + "-" + temp_d
						// console.log(333333333555555, temp_stopat)
						let temp_vip = 0
						if (res.data.stop_at >= temp_stopat) {
							temp_vip = 1
						}
						localStorage.setItem('vip', temp_vip)

						window.sessionStorage.setItem('token', res.data.token)
						this.$store.commit('userIdTions', res.data.user_id)
						this.$store.commit('isTest', res.data.is_test)
						this.$store.commit('userName', res.data.name)
						this.$store.commit('userMobile', res.data.mobile)
						this.$store.commit('userUnit_name', res.data.unit_name)
						// this.$store.commit('userStopat', res.data.stop_at)
						this.$store.commit('userOpenid', res.data.openid)
						localStorage.setItem('user_stopat', res.data.stop_at)
						if (temp_vip != 1) {
							this.$alert('非VIP账号只可浏览，不可下载。', '提示', {
								confirmButtonText: '确定',
							});
						};
						this.$router.push('/Home');
					}

				})
			},
			// 扫码登录
			codeLogin() {
				request({
					url: 'createCodeUrl',
					method: 'get',
				}).then(res1 => {
					// console.log(66666, res1);
					this.img_url = "http://www.szdunan.cn/" + res1.data.codeUrl;
					// console.log(this.img_url);
					this.timer = setInterval(() => {
						request({
								url: 'datum/code_login',
								method: 'post',
								data: {
									code: res1.data.code,
								}
							})
							.then((res) => {
								//console.log(res.data.data.code);
								if (res.code == 1) {
									clearInterval(this.timer);
									let temp_time = new Date()
									// console.log(333333333, temp_time)
									let temp_y = temp_time.getFullYear();
									let temp_m = (temp_time.getMonth() + 1).toString().padStart(2, '0')
									let temp_d = temp_time.getDate().toString().padStart(2, '0')
									let temp_stopat = temp_y + "-" + temp_m + "-" + temp_d
									// console.log(333333333555555, temp_stopat)
									let temp_vip = 0
									if (res.data.stop_at >= temp_stopat) {
										temp_vip = 1
									}
									localStorage.setItem('vip', temp_vip)
										window.sessionStorage.setItem('token', res.data.token)
										this.$store.commit('userIdTions', res.data.user_id)
										this.$store.commit('isTest', res.data.is_test)
										this.$store.commit('userName', res.data.name)
										this.$store.commit('userMobile', res.data.mobile)
										this.$store.commit('userUnit_name', res.data.unit_name)
										// this.$store.commit('userStopat', res.data.stop_at)
										this.$store.commit('userOpenid', res.data.openid)
										localStorage.setItem('user_stopat', res.data.stop_at)
										this.$router.push('/Home')
									if (temp_vip != 1) {
										this.$alert('非VIP账号只可浏览，不可下载资料。', '提示', {
											confirmButtonText: '确定',
										});
									}
									this.$router.push('/Home')
								}
							});
					}, 1000);
				});

			},
		}
	}
</script>

<style lang="less">
	.Login {
		background-size: 100% 100%;
		background-image: url(../assets/login/login1.png);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login_img {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
	}

	.login_from {
		position: absolute;
		right: 100px;
		top: 200px;
		height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-end;
	}


	.item4,
	.item2 {
		width: 300px;

		.el-tabs__item {
			font-weight: bold;
			font-size: 20px;
		}
	}

	.bottom_text {
		cursor: pointer;
		color: #409EFF;
	}
</style>
